import {
   convertCartLineItemsToAnalyticsItem,
   trackInAnalyticsAddToCart,
   trackInAnalyticsRemoveFromCart,
   trackPiwikCartUpdate,
} from '@ifixit/analytics';
import { type LanguageCode, useShopifyStorefrontClient } from '@ifixit/shopify-storefront-client';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCartLines } from '../../helpers/storefront-api';
import { buildIfixitCart } from '../../models/cart';
import type { Cart, CartLineItem } from '../../types';
import { CART_MUTATION_KEY, cartKeys } from '../../utils';
import { useCart, useCartToasts } from './use-cart';

export type UpdateLineItemQuantityInput = {
   line: CartLineItem;
   quantityDelta: number;
   analytics: {
      localeCode: string;
   };
};

const META_TYPE = 'update-line-item-quantity';

export function useUpdateLineItemQuantity({ language }: { language: LanguageCode }) {
   const cart = useCart({ language }).data;
   const isMutating = useIsMutating({
      mutationKey: CART_MUTATION_KEY,
      predicate: mutation => mutation.meta?.type !== META_TYPE,
   });
   const queryClient = useQueryClient();
   const { client: storefrontClient, currencyCode } = useShopifyStorefrontClient();
   const { showCartWarnings, showErrorToast } = useCartToasts();
   const mutation = useMutation({
      mutationKey: CART_MUTATION_KEY,
      meta: { type: META_TYPE },
      mutationFn: async ({ line, quantityDelta }: UpdateLineItemQuantityInput) => {
         if (cart == null || !cart.shopifyCartId || !line?.shopifyLineId) {
            return buildIfixitCart({ cart: null, fallbackCurrencyCode: currencyCode });
         }
         const result = await updateCartLines(storefrontClient, {
            cartId: cart.shopifyCartId,
            lines: [{ id: line.shopifyLineId, quantity: line.quantity + quantityDelta }],
            language,
         });
         return buildIfixitCart({
            cart: result.cart ?? null,
            fallbackCurrencyCode: currencyCode,
            userErrors: result.userErrors,
            validationErrors: result.validationErrors,
         });
      },
      onMutate: async ({ line, quantityDelta }: UpdateLineItemQuantityInput) => {
         await queryClient.cancelQueries({ queryKey: cartKeys.cart });

         const previousCart = queryClient.getQueryData<Cart>(cartKeys.cart);

         queryClient.setQueryData<Cart | undefined>(cartKeys.cart, current => {
            if (current == null) {
               return current;
            }
            const updatedItem = current.lineItems.find(item => item.itemcode === line.itemcode);
            if (updatedItem == null) {
               return current;
            }
            const updatedItemsCount = Math.max(current.totals.itemsCount + quantityDelta, 0);
            const updateTotalPrice = Math.max(
               Number(current.totals.price.amount) + quantityDelta * Number(line.price.amount),
               0
            ).toFixed(2);
            return {
               ...current,
               hasItemsInCart: updatedItemsCount > 0,
               lineItems: current.lineItems.map(lineItem => {
                  if (lineItem.itemcode === line.itemcode) {
                     const updatedQuantity = Math.max(lineItem.quantity + quantityDelta, 0);
                     return {
                        ...lineItem,
                        quantity: updatedQuantity,
                     };
                  }
                  return lineItem;
               }),
               totals: {
                  ...current.totals,
                  price: {
                     ...current.totals.price,
                     amount: updateTotalPrice,
                  },
                  itemsCount: updatedItemsCount,
               },
            };
         });

         return { previousCart };
      },
      onError: (error, variables, context) => {
         queryClient.setQueryData<Cart | undefined>(cartKeys.cart, context?.previousCart);
         showErrorToast();
      },
      onSuccess: (cart, { line, quantityDelta, analytics }) => {
         showCartWarnings(cart);

         const analyticsItems = convertCartLineItemsToAnalyticsItem([line]);
         const addOrRemoveAnalyticsItems = analyticsItems.map(item => ({
            ...item,
            quantity: Math.abs(quantityDelta),
         }));
         const trackChangeInCart =
            quantityDelta < 0 ? trackInAnalyticsRemoveFromCart : trackInAnalyticsAddToCart;
         trackChangeInCart({
            items: addOrRemoveAnalyticsItems,
            value: Number(line.price.amount),
            currency: line.price.currencyCode,
            localeCode: analytics.localeCode,
         });
         trackPiwikCartUpdate({
            items: convertCartLineItemsToAnalyticsItem(cart.lineItems),
            value: Number(cart.totals.price.amount),
            currency: cart.totals.price.currencyCode,
            localeCode: analytics.localeCode,
         });
      },
      onSettled: () => queryClient.invalidateQueries({ queryKey: cartKeys.cart }),
   });
   return { updateLineItemQuantity: mutation, enabled: cart != null && !isMutating };
}
