import {
   convertCartLineItemsToAnalyticsItem,
   trackInAnalyticsRemoveFromCart,
   trackPiwikCartUpdate,
} from '@ifixit/analytics';
import { type LanguageCode, useShopifyStorefrontClient } from '@ifixit/shopify-storefront-client';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { removeCartLines } from '../../helpers/storefront-api';
import { buildIfixitCart } from '../../models/cart';
import type { Cart, CartLineItem } from '../../types';
import { CART_MUTATION_KEY, cartKeys } from '../../utils';
import { useCart, useCartToasts } from './use-cart';

export type RemoveLineItemInput = {
   line: CartLineItem;
   analytics: {
      localeCode: string;
   };
};

const META_TYPE = 'remove-line-item';

export function useRemoveLineItem({ language }: { language: LanguageCode }) {
   const cart = useCart({ language }).data;
   const isMutating = useIsMutating({
      mutationKey: CART_MUTATION_KEY,
      predicate: mutation => mutation.meta?.type !== META_TYPE,
   });
   const queryClient = useQueryClient();
   const { client: storefrontClient, currencyCode } = useShopifyStorefrontClient();
   const { showCartWarnings, showErrorToast } = useCartToasts();
   const mutation = useMutation({
      mutationKey: CART_MUTATION_KEY,
      meta: { type: META_TYPE },
      mutationFn: async ({ line }: RemoveLineItemInput) => {
         if (cart == null || !cart.shopifyCartId || !line?.shopifyLineId) {
            return buildIfixitCart({ cart: null, fallbackCurrencyCode: currencyCode });
         }
         const result = await removeCartLines(storefrontClient, {
            cartId: cart.shopifyCartId,
            lineIds: [line.shopifyLineId],
            language,
         });
         return buildIfixitCart({
            cart: result.cart ?? null,
            fallbackCurrencyCode: currencyCode,
            userErrors: result.userErrors,
            validationErrors: result.validationErrors,
         });
      },
      onMutate: async (input: RemoveLineItemInput) => {
         await queryClient.cancelQueries({ queryKey: cartKeys.cart });

         const previousCart = queryClient.getQueryData<Cart>(cartKeys.cart);

         queryClient.setQueryData<Cart | undefined>(cartKeys.cart, current => {
            if (current == null) {
               return current;
            }

            const deletedItem = current.lineItems.find(
               item => item.itemcode === input.line.itemcode
            );
            if (deletedItem == null) {
               return current;
            }
            const updatedItemCount = Math.max(current.totals.itemsCount - deletedItem.quantity, 0);
            const updateTotalPrice = Math.max(
               Number(current.totals.price.amount) -
                  deletedItem.quantity * Number(deletedItem.price.amount),
               0
            ).toFixed(2);
            return {
               ...current,
               hasItemsInCart: updatedItemCount > 0,
               isEmpty: updatedItemCount === 0,
               lineItems: current.lineItems.filter(
                  product => product.itemcode !== input.line.itemcode
               ),
               totals: {
                  ...current.totals,
                  price: {
                     ...current.totals.price,
                     amount: updateTotalPrice,
                  },
                  itemsCount: updatedItemCount,
               },
            };
         });

         return { previousCart };
      },
      onError: (error, variables, context) => {
         queryClient.setQueryData<Cart | undefined>(cartKeys.cart, context?.previousCart);
         showErrorToast();
      },
      onSuccess: (cart, { line, analytics }) => {
         showCartWarnings(cart);

         trackInAnalyticsRemoveFromCart({
            items: convertCartLineItemsToAnalyticsItem([line]),
            value: line.quantity * Number(line.price.amount),
            currency: line.price.currencyCode,
            localeCode: analytics.localeCode,
         });
         trackPiwikCartUpdate({
            items: convertCartLineItemsToAnalyticsItem(cart.lineItems),
            value: Number(cart.totals.price.amount),
            currency: cart.totals.price.currencyCode,
            localeCode: analytics.localeCode,
         });
      },
      onSettled: () => queryClient.invalidateQueries({ queryKey: cartKeys.cart }),
   });
   return { removeLineItem: mutation, enabled: cart != null && !isMutating };
}
